.art-ski {
    min-width: 850px;
    max-height: 700px;
    background: rgba(211, 211, 211, 0.8);
    border: 3px solid #f1f1f1;
    position: absolute;
    padding: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-family: sans-serif;
    font-size: 1.0em;
    overflow-y: scroll;
}
.art-ski-closer {
    font-size: 2em;
    position: absolute;
    top: 5px;
    right: 20px;
    cursor: pointer;
}
.art-ski-closer:hover {
    color: red;
}
.art-ski ol {
    list-style-type: square;
}
.art-ski ol li {
    padding: 5px 0;
}
.art-ski ol li b {
    font-size: 1.05em;
}
.token {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.jduke-token {
    width: 700px;
    height: 500px;
    margin: 20px 0;
}
.token div {
    color: rgba(255,0,0,0.8);
    font-style: italic;
}
.fx-app {
    width: 700px;
    height: 900px;
    margin: 20px 0;
}
.pm-app {
    width: 700px;
    height: 900px;
    margin: 20px 0;
}
a {
    color: blue;
    text-decoration: none;
    font-weight: bold;
}
a:visited {
    color: blue;
}