@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&family=Philosopher&display=swap");


.art-arts  {
    min-width: 850px;
    min-height: 700px;
    background: rgba(150, 200, 120, 0.8);
    border: 3px solid #f1f1f1;
    position: absolute;
    padding: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-family: 'Philosopher';
    font-size: 1.0em;
    overflow-y: scroll;
}
.art-arts-closer {
    font-size: 2em;
    position: absolute;
    top: 5px;
    right: 20px;
    cursor: pointer;
}
.art-arts-closer:hover {
    color: red;
}
.art-arts-list {
    list-style-type: none;
    cursor: pointer;
}
.art-arts-list li {
    width: 100px;
}
.art-arts-list li:hover {
    background-color: #f1f1f1;
} 
p b {
    color: rgba(255, 0, 0, 0.7);
}
.more {
    font-size: 0.9em;
    position: absolute;
    top: 90%;
}
