@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');


.logo-container {
    width: 100%;
    height: auto;
    background-color: black;
    font-family: 'Great Vibes';
    font-size: 3em;
    margin: 0;
    padding: 8px 0px;
    display: flex;
}
.l {
    border-bottom: 3px solid red;
    margin-top: 0.75em;
    color: gold;
}
.p {
    border-top: 3px solid gold;
    margin-left: 0.2em;
    color: red;
}
.ext {
    margin-left: -0.2em;
    margin-top: 0.6em;
    color: gold;
}
.less {
    font-size: medium;
    margin-left: auto;
    color: gold;
    align-self: flex-end;
}