.art-was {
    min-width: 850px;
    min-height: 700px;
    background: rgba(211, 211, 211, 0.8);
    border: 3px solid #f1f1f1;
    position: absolute;
    padding: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-family: sans-serif;
    font-size: 1.0em;
    overflow-y: scroll;
}
.art-was-closer {
    font-size: 2em;
    position: absolute;
    top: 5px;
    right: 20px;
    cursor: pointer;
}
.art-was-closer:hover {
    color: red;
}
.art-was-top {
    display: flex;
    background-color: white;
    justify-content: center;
}
.art-was-bottom h3 {
    font-style: italic;
    text-align: center;
}
.input-name {
    margin: 10px 0;
    font-size: 1.3em;
    padding: 10px;
    width: 250px;
    height: 40px;
    border: 3px solid black;
    background: rgb(240, 230, 230);
    outline: none;
    align-self: center;
}
.btnGreet {
    background-color: black;
    border-radius: 12px;
    color: #fff;
    cursor: pointer;
    font-size: 1.2em;
    padding: 8px 0.8em;
    text-align: center;
    white-space: nowrap;
    margin: 10px 15px;
}
.btnGreet {
    margin-right: 20px;
}
.btnGreet:hover,
.btnGreet:focus {
    color: goldenrod;
    background-color: rgba(255,255,255,0.5);
}
.btnGreet:disabled {
    color: darkgray;
    background-color: darkgray;
    cursor: auto;
}
canvas {
    background: black;
    width: 100%;
}