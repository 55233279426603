@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=IBM+Plex+Mono:wght@300&family=Philosopher&display=swap');


.App {
  margin: 0;
  padding: 0;
  background-image: url('../images/life_tree.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 800px;
  position: relative;
}
.explore-container {
  font-family: 'IBM Plex Mono';
  margin: 0;
  display: flex;
  flex-direction: column;
  color: black;
  position: relative;
  left: 10%;
  top: 120px;
  justify-content: center;
}
.explore {
  cursor: pointer;
  color: rgba(0,0,0,0.7);
  width: 120px;
  font-size: 0.6em;
  text-align: center;
  transition: color 1.5s;
  transition: font-size 1.5s;
}
.explore:hover {
  color: red;
  font-size: 1.1em;
}
.menu-list {
  max-width: 120px;
  padding: 3px;
  list-style-type: none;
}
.menu-list li {
  font-size: 0.9em;
  color: grey;
  cursor: pointer;
  transition: padding 0.4s linear;
}
.menu-list li:hover {
  color: red;
  padding-left: 7px;
  background-color: rgba(128, 128, 128, 0.2);
}
.hide-me {
  visibility: hidden;
}
