.art-mis {
    min-width: 850px;
    min-height: 700px;
    background: rgba(211, 211, 211, 0.7);
    border: 3px solid #f1f1f1;
    position: absolute;
    padding: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-family: sans-serif;
    font-size: 1.0em;
    overflow-y: scroll;
}
.art-mis-closer {
    font-size: 2em;
    position: absolute;
    top: 5px;
    right: 20px;
    cursor: pointer;
}
.art-mis-closer:hover {
    color: red;
}
ol {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: start;
}