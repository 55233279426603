@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&family=Philosopher&display=swap");


.art-det {
    width: 900px;
    height: 600px;
    background: rgba(211, 211, 211, 0.8);
    border: 3px solid #f1f1f1;
    position: absolute;
    padding: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-family: 'Philosopher';
    font-size: 1.0em;
    overflow-y: scroll;
}
.art-det-closer {
    font-size: 2em;
    position: absolute;
    top: 5px;
    right: 20px;
    cursor: pointer;
}
.art-det-closer:hover {
    color: red;
}